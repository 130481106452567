body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.mapboxgl-ctrl-logo {
  visibility: hidden;
}

.mapboxgl-ctrl-attrib {
  visibility: hidden;
}


.mapboxgl-popup-content {
  width: 250px;
}

.image-gallery-thumbnail-image {
  height: 60px !important
}

.mapboxgl-ctrl-bottom-left {
  display: flex;
  justify-content: flex-start;
}

.bottom-buttons-button {
  width: 143px;
  height: 40px;
  background-color: #d5dadd;
  border: 4px solid white;

  border-radius: 8px;
  padding-left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  bottom: 0px;
}

.mapboxgl-popup-close-button {
  font-size: 32px !important;
}

@media screen and (max-width:800px) {
  /* .mapboxgl-ctrl-bottom-left {
    bottom: 30px !important;
    left: 0px !important;
  } */

  .bottom-buttons-container {
    background-color: rgba(0, 0, 0, 0.6);
    bottom: 0px;
    padding-bottom: 10px;
    padding-top: 10px;
  }

  .bottom-buttons-button {
    height: 30px;
    width: 110px;
  }

}